<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center mb-3 subtitle-4 black--text"> الدورات </h1>
        <v-row>
          <v-col md="4" cols="12">
            <div class="d-md-flex">
              <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" :block="isScreenXs" @click="goToAddPage">
                اضافة دورة <v-icon right> fa-plus </v-icon>
              </v-btn>
              <!-- <v-btn tile color="success" :block="isScreenXs" :loading="xlsxData.downloadLoading"
                @click="handleDownload"> تحميل اكسل <v-icon right> fa-download </v-icon>
              </v-btn> -->
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3" sm="3" cols="12">
            <div class="d-flex flex-row">
              <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
              <button class="search-btn" @click="search()">
                <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
              </button>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="table.loading"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :headers="headers"
              :items="driversData"
              :search="table.search"
              class="elevation-1"
              :options.sync="tableOptions"
              :server-items-length="table.totalTeacherData"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }">
              <template slot="item._id" slot-scope="props"> {{ props.index + 1 }} </template>
              <template v-slot:item.title="{ item }">
                <router-link :to="'/course/' + item._id + '/' + item.title"> {{ item.title }}
                </router-link>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="EditPassword(item)">
                      fa-edit </v-icon>
                  </template>
                  <span>تعديل </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)"> fa-trash
                    </v-icon>
                  </template>
                  <span>حذف</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- image Teacher dialog -->
    <v-dialog v-model="table.showImageDailog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img :src="content_url + table.imageUrlForShow" alt width="300" height="200" />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="table.showImageDailog = false"> الغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذه الدورة ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dailog -->
    <!-- Edit dialog -->
    <v-dialog v-model="EditPasswordDialog" max-width="790px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل الدورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isFormValidEditPassword">
              <v-row>
                <!-- name -->
                <v-col md="4" sm="6" cols="12">
                  <v-text-field v-model="editedItem.title" :rules="Rules.titleRules" dense label="عنوان الدورة"
                    outlined></v-text-field>
                </v-col>
                <!-- birthday -->
                <v-col md="4" sm="6" cols="12">
                  <v-menu v-model="menuAccountBirthday" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.start_date" :rules="Rules.start_date" dense
                        label="تاريخ البدء" outlined clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.start_date" @input="menuAccountBirthday = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- birthday -->
                <v-col md="4" sm="6" cols="12">
                  <v-menu v-model="menuAccountBirthda" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.end_date" :rules="Rules.end_date" dense
                        label="تاريخ الأنتهاء" outlined clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.end_date" @input="menuAccountBirthda = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- address -->
                <v-col md="4" sm="6" cols="12">
                  <v-text-field v-model="editedItem.price" dense label="سعر الدورة" outlined></v-text-field>
                </v-col>
                <!-- mobile -->
                <v-col md="12" sm="12" cols="12">
                  <v-textarea v-model="editedItem.description" dense label="وصف الدورة" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="EditPasswordDialog = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="EditPasswordLoading" :disabled="!isFormValidEditPassword"
            @click="submitEditTeacher"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Edit dialog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
// import sha512 from 'js-sha512'

export default {
  data() {
    return {
      isFormValidEditPassword: false,

      EditPasswordDialog: false,

      EditPasswordLoading: false,

      deleteItemLoading: false,

      Rules: {
        titleRules: [value => !!value || 'العنوان مطلوب'],
        account_emailRules: [
          value => !!value || 'الايميل مطلوب',

          // (value) =>
          //   (value && value.length > 4) || "خمسة احرف او ارفام على الاقل",
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'يجب ادخال عنوان بريد الكتروني صالح',
        ],
        start_date: [value => !!value || 'تاريخ البدء مطلوب'],
        end_date: [value => !!value || 'تاريخ الأنتهاء مطلوب'],
        account_birthdayRules: [value => !!value || 'يوم الميلاد مطلوب'],
        account_genderRules: [value => !!value || 'الجنس مطلوب'],
        account_addressRules: [value => !!value || 'العنوان مطلوب'],
        account_mobile: [
          value => (value && value.length === 11) || 'يجب ان يكون 11 رقم',
          value => /^\d*\.?\d*$/.test(value) || 'يجب ان تكون ارقام',
        ],
        salary: [
          value => !!value || 'مبلغ القسط الكلي مطلوب',
          value => (value >= 1000 && value <= 5000000) || value == 0 || 'يجب ان يكون الرقم بين 1000 و 5000000',
          value => /^\d*\.?\d*$/.test(value) || 'يجب ان تكون ارقام',

          // value => /^($|[^0])/.test(value) || 'يجب ان لا يكتب الصفر في البداية',
        ],
        payment: [
          value => !!value || 'مبلغ الواصل مطلوب',
          value => (value >= 1000 && value <= 5000000) || value == 0 || 'يجب ان يكون الرقم بين 1000 و 5000000',
        ],
        withDraw: [
          value => !!value || 'مبلغ الخصم مطلوب',
          value => (value >= 1000 && value <= 5000000) || value == 0 || 'يجب ان يكون الرقم بين 1000 و 5000000',
        ],
        newPaymentDate: [value => !!value || 'تاريخ الدفعة القادمة مطلوب'],
      },
      menuAccountBirthday: false,
      menuAccountBirthda: false,

      editedItem: {},

      isScreenXs: false,

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'السواق',
        autoWidth: true,
        bookType: 'xlsx',
      },

      editRules: {
        passwordRules: [
          value => !!value || 'الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],

        ensurePasswordRules: [
          value => !!value || 'تاكيد الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
      },

      dialogDelete: false,
      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },
      tableOptions: {},
      table: {
        loading: false,
        search: null,
        imageUrlForShow: null,
        showImageDailog: false,
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: '_id',
        },
        {
          text: 'عنوان الدورة',
          value: 'title',
        },
        { text: 'السعر', value: 'price' },
        { text: 'تاريخ البدء', value: 'start_date' },
        { text: 'تاريخ الأنتهاء', value: 'end_date' },
        { text: 'عدد الطلاب', value: 'student_number' },
        { text: 'وصف الدورة', value: 'description' },
        { text: 'العمليات', value: 'actions', sortable: false },
      ],
      driversData: [],
      content_url: null,
    }
  },
  watch: {
    tableOptions: {
      handler() {
        const resultsLocalStorage = JSON.parse(localStorage.getItem('results'))
        this.featuredFingerId = resultsLocalStorage.features_finger_id
        this.getData()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getData(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler() {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await Api.getCourses(page, itemsPerPage, search)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.table.loading = false
        this.driversData = response.data.results.data
        this.content_url = response.data.content_url
        this.table.totalTeacherData = response.data.results.count
        this.content_url = response.data.content_url

        console.log(response)
      }
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await Api.deleteCourse(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    EditPassword(item) {
      this.editedItem = { ...item }
      this.EditPasswordDialog = true
    },

    async submitEditTeacher() {
      this.EditPasswordLoading = true

      const response = await Api.editCourse({
        course_id: this.editedItem._id,
        title: this.editedItem.title,
        description: this.editedItem.description,
        start_date: this.editedItem.start_date,
        end_date: this.editedItem.end_date,
        price: this.editedItem.price,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.EditPasswordDialog = false
        this.EditPasswordLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.EditPasswordLoading = false
        this.EditPasswordDialog = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    goToAddPage() {
      this.$router.push('/courses/addCourse')
    },

    showImage(image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    handleDownload() {
      this.xlsxData.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        // console.log(this.studentsData)
        const tHeader = ['الاسم', 'الايميل', 'الرمز', 'الهاتف', 'العنوان', 'الراتب', 'تاريخ الميلاد', 'عدد الطلاب']
        const filterVal = [
          'account_name',
          'account_email',
          'account_password_show',
          'account_mobile',
          'account_address',
          'account_salary',
          'account_birthday',
          'number_of_student',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.driversData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
  },
}
</script>

<style>
.teacher_image_table {
  cursor: pointer;
}
</style>

<style scoped>
a {
  text-decoration: none;
}
</style>
